@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}

*::-webkit-scrollbar-track {
  background: #f9f9f9;
  border-radius: 10px;
}

@layer base {
  :root {
    --color-bg-primary: #121212;
    --color-bg-secondary: #121212;
    --color-bg-inverse: #ffffff;

    --color-text-primary: #ffffff;
    --color-text-inverse: #121212;

    --color-btn-bg-primary: #28f0d9;
    --color-btn-text-primary: #121212;

    --color-border-primary: #121212;
  }
}

[data-theme='hotelDE'] {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #f0f0f0;
  --color-bg-inverse: #121212;

  --color-text-primary: #121212;
  --color-text-inverse: #ffffff;

  --color-btn-bg-primary: #ff7500;
  --color-btn-text-primary: #ffffff;

  --color-border-primary: #ffffff;
}

/* nextjs-portal {
  display: none;
} */
